import React, {Component} from 'react';
import {FieldArray} from 'redux-form';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_DIC_NUMBER, FORM_FIELD_MANAGEMENT_GROUP, FORM_FIELD_PART_NO, FORM_FIELD_SUBJECT_TO_VOR, FORM_FIELD_VIN,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_LABEL_DIC_NUMBER,
    FORM_ORDERS_SECTION, FORM_VEHICLE_DETAIL_SECTION,
} from '../../../../../../constants/formConstants';
import {
    DIC_FIELD_MAX_LENGTH,
    MAX_SPARE_PARTS_VOR_PVI_ORDER_COUNT,
} from '../../../../../../constants/validationConstants';
import {
    BorderlessPrimaryIcon,
    StyledDiv
} from '../../../../../common/StyledComponents';
import styled from 'styled-components';
import OrdersVorPviTableHeader from './OrdersVorPviTableHeader';
import OrdersVorPviTableBody from './OrdersVorPviTableBody';
import {
    fetchDicSparePartInfoActionCreator, fetchVinDicStatusActionCreator,
    setDicSparePartInfoActionCreator
} from '../../../../../../actions/orders/actionSparePartsDicData';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, formValueSelector} from 'redux-form';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import {FormattedMessage} from 'react-intl';
import {formatTicketNumber} from '../../../../../../utils/utils';
import {onlyDigits} from '../../../../../common/normalizationCommons';
import {warnAlert} from '../../../../../../actions/alertsActions';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {logger} from '../../../../../../utils/winstonLogger';

export const Div = styled.div`
  &&{background-color: #e9ecef;};
`;

class OrderItemsVorPviSection extends Component {
    handleDicTransfer = () => {
        const {fetchVinDicStatusActionCreator, dicInfo, vin, sfxNumber, pfxNumber} = this.props;
        if (!dicInfo.dicVinStatusLoaded) {
            // log DIC loading start
            logger.info(JSON.stringify(["DIC_LOG", "START", fetchVinDicStatusActionCreator, dicInfo, vin, sfxNumber, pfxNumber]))
            console.log(JSON.stringify(["DIC_LOG", "START", fetchVinDicStatusActionCreator, dicInfo, vin, sfxNumber, pfxNumber]))
            fetchVinDicStatusActionCreator(vin, formatTicketNumber(pfxNumber, sfxNumber));
        }
    };

    handleOrderPartLoad = (index) => {
        const {orders, fetchDicSparePartInfoActionCreator} = this.props;

        fetchDicSparePartInfoActionCreator(orders[index][FORM_FIELD_PART_NO]);
    };

    fillDicSparePartInfo = () => {
        const {dicInfo, orders, change, setDicSparePartInfoActionCreator, warnAlert, userRights} = this.props;
        const order = orders.find(e => e[FORM_FIELD_PART_NO] === dicInfo.partNo);
        setDicSparePartInfoActionCreator();
        if (order) {
            if(userRights.canEditItems) {
                if (!order[FORM_FIELD_DESCRIPTION]) {
                    change(`${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}[${order.index}].${FORM_FIELD_DESCRIPTION}`, dicInfo.partDescription.trim());
                    change(`${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}[${order.index}].${FORM_FIELD_SUBJECT_TO_VOR}`, dicInfo.vorStatus ? 'YES' : 'NO');

                    if(!dicInfo.vorStatus) {
                        warnAlert(warnMessages.SP_DIC_WARN005)
                    }
                } else {
                    warnAlert(warnMessages.SP_DIC_WARN002, null, {partNumber: dicInfo.partNo})
                }
            }
            if (!order[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}[${order.index}].${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.SP_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.SP_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

    renderOrders = ({fields}) => {
        const {userRights, readOnly, form, reqFields} = this.props;
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <OrdersVorPviTableHeader userRights={userRights} readOnly={readOnly}
                                                 reqFields={reqFields}/>
                        <tbody>
                        {fields.map((order, index) => <OrdersVorPviTableBody key={index} form={form} index={index}
                                                                             order={order}
                                                                             userRights={userRights} readOnly={readOnly}
                                                                             handleRemoveOrder={() => {
                                                                                 fields.remove(index)
                                                                             }}
                                                                             reqFields={reqFields}
                                                                             handleDicTransfer={() => this.handleDicTransfer(index)}
                                                                             handleOrderPartLoad={this.handleOrderPartLoad}
                        />)}
                        </tbody>
                    </table>
                </div>
                {userRights.canEditItems && !readOnly &&
                <BorderlessPrimaryIcon type="button" className="btn float-right"
                                       onClick={() => fields.push({index: fields.length})}
                                       disabled={fields.length >= MAX_SPARE_PARTS_VOR_PVI_ORDER_COUNT}>
                    <FormattedMessage {...buttonMessages.ADD}/>
                </BorderlessPrimaryIcon>
                }
            </>
        )
    };

    render() {
        const {userRights, dicInfo, createdInDic, readOnly} = this.props;
        if (dicInfo.dicPartInfoLoaded) {
            this.fillDicSparePartInfo();
        }
        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.ORDERS_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {userRights.canViewMpr && <Div className="d-flex p-1 border border-bottom-secondary pl-2">
                        <label className="col-form-label-sm pl-0 font-weight-bold pr-2 mb-0">
                            <ValidFormattedMessage message={FORM_LABEL_DIC_NUMBER} intlMessages={formMessages}/>
                        </label>
                        <InputFieldWithValidation
                            field={FORM_FIELD_DIC_NUMBER}
                            maxLength={DIC_FIELD_MAX_LENGTH}
                            isDisabled={!userRights.canEditDic || !!createdInDic || readOnly}
                            normalize={onlyDigits}
                            isSmall
                            parse={(e) => e ? Number(e) + '' : null}
                        />
                    </Div>}
                    <div className="d-flex justify-content-md-center">
                        <FieldArray name={FORM_FIELDS_ORDER_ITEMS} component={this.renderOrders}/>
                    </div>
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    orders: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}`),
    vin: formValueSelector(props.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VIN}`),
    dicInfo: state.sparePartsDic,
    sfxNumber: state.sparePartsOrderTicket.ticket.sfxNumber,
    pfxNumber: state.sparePartsOrderTicket.ticket.pfxNumber,
    createdInDic: state.sparePartsOrderTicket.ticket.createdInDic,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicSparePartInfoActionCreator,
            fetchDicSparePartInfoActionCreator,
            fetchVinDicStatusActionCreator,
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsVorPviSection);
