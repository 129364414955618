import {
    ADD_SPARE_PARTS_ORDERS_DIC_VALIDATIONS, DIC_SPARE_PART_CLOSE_MODAL,
    DIC_SPARE_PART_INFO_SETTED,
    DIC_SPARE_PART_RESET
} from '../../actions/orders/actionSparePartsDicData';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {logger} from '../../utils/winstonLogger';


export const sparePartsDic = (state = {
    isModalActive: false,
    partNo: {},
    partDescription: {},
    vorStatus: {},
    dicPartInfoLoaded: false,
    vinNumber: {},
    dicVinStatusLoaded: false,
    canCreateDic: false,
    dicValidationMessages: [],
    isCreateOrUpdateDicLoading: false
}, action) => {
    switch (action.type) {
        case DIC_SPARE_PART_RESET: {
            return Object.assign({}, state, {
                isModalActive: false,
                partNo: {},
                partDescription: {},
                vorStatus: {},
                dicPartInfoLoaded: false,
                vinNumber: {},
                dicVinStatusLoaded: false,
                canCreateDic: false,
                dicValidationMessages: [],
                isCreateOrUpdateDicLoading: false,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_PART_INFO_GET: {
            return Object.assign({}, state, {dicPartInfoLoaded: false, isModalActive: true});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_PART_INFO_SEND: {
            const {partNo, partDescription, vorStatus, correlationId, managementGroup} = action.payload;
            if (correlationId < state.partInfoCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                partInfoCorrelationId: correlationId,
                partNo,
                partDescription,
                vorStatus,
                managementGroup,
                dicPartInfoLoaded: true,
                isModalActive: false
            });
        }
        case DIC_SPARE_PART_INFO_SETTED: {
            return Object.assign({}, state, {
                partNo: {},
                partDescription: {},
                vorStatus: {},
                isModalActive: false,
                dicPartInfoLoaded: false
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_PART_INFO_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.partInfoCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                partNo: {},
                partDescription: {},
                vorStatus: {},
                isModalActive: false,
                dicPartInfoLoaded: false,
                partInfoCorrelationId: correlationId,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_VIN_STATUS_GET: {
            return Object.assign({}, state, {isModalActive: true, dicVinStatusLoaded: false});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_VIN_STATUS_INFO_SEND: {
            const {vinNumber, canBeCreated, correlationId} = action.payload;
            if (correlationId < state.vinStatusCorrelationId) {
                return state;
            }
            // log DIC loading end
            logger.info(JSON.stringify(["DIC_LOG", "END", action.payload]))
            console.log(JSON.stringify(["DIC_LOG", "END", action.payload]))
            return Object.assign({}, state, {
                vinStatusCorrelationId: correlationId,
                vinNumber,
                canCreateDic: canBeCreated,
                isModalActive: false,
                dicVinStatusLoaded: true,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_VIN_STATUS_INFO_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.vinStatusCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                vinStatusCorrelationId: correlationId,
                vinNumber: {},
                canCreateDic: false,
                isModalActive: false,
                dicVinStatusLoaded: true
            });
        }
        case ADD_SPARE_PARTS_ORDERS_DIC_VALIDATIONS: {
            return Object.assign({}, state, {
                dicValidationMessages: action.payload.dicValidationMessages,
            });
        }
        case DIC_SPARE_PART_CLOSE_MODAL: {
            return Object.assign({}, state, {
                isModalActive: false,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_UPDATE:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_CREATE: {
            return Object.assign({}, state, {
                isCreateOrUpdateDicLoading: true,
                isModalActive: true,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_CREATED:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_UPDATED:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_UPDATE_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_CREATE_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.createOrUpdateCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                createOrUpdateCorrelationId: correlationId,
                isCreateOrUpdateDicLoading: false,
                isModalActive: false,
            });
        }
        default:
            return state;
    }
};
